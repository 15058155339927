import { Dimensions, Platform, PixelRatio } from 'react-native';

export const normalize = size => {
  const { width: SCREEN_WIDTH } = Dimensions.get('window');
  const scale = SCREEN_WIDTH / 414;
  const newSize = size * scale;

  if (Platform.OS === 'ios') {
    return PixelRatio.roundToNearestPixel(newSize);
  } else {
    return PixelRatio.roundToNearestPixel(newSize);
  }
};

export const loadScript = src => {
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', e => {
      reject(e);
    });
    document.body.appendChild(script);
  });
};
