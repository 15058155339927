import { fetchData } from '../services/fetchApi';
import { saveAchievedMissions, resetAchievedMissions } from '../services/storage';

export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SAVE_QUIZ_RESULT = 'SAVE_QUIZ_RESULT';
export const RESET_QUIZ_RESULT = 'RESET_QUIZ_RESULT';
export const INIT_ACHIEVED_MISSIONS = 'INIT_ACHIEVED_MISSIONS';

export const fetchDataRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDataSuccess = payload => {
  return {
    type: FETCH_SUCCESS,
    payload,
  };
};

export const fetchDataError = () => {
  return {
    type: FETCH_ERROR,
  };
};

export const mergeDataFromApiWithStorage = data => (dispatch, getState) => {
  const { achievedBuildings } = getState();

  const mergedData = data.map(pinpoint => {
    if (achievedBuildings.has(pinpoint.id)) {
      const previousSuccess = achievedBuildings.get(pinpoint.id);
      pinpoint.success = previousSuccess;
    }
    return pinpoint;
  });

  dispatch({
    type: FETCH_SUCCESS,
    value: mergedData,
  });
};

export const fetchDataWithRedux = ({ listId }) => {
  return dispatch => {
    // notify app to starting data download
    dispatch(fetchDataRequest());

    // preocess to download
    return fetchData({ listId }).then(([response, json]) => {
      if (response.status === 200) {
        const data = json.results;
        dispatch(mergeDataFromApiWithStorage(data));
      } else {
        dispatch(fetchDataError());
      }
    });
  };
};

export const resetQuizz = callback => dispatch => {
  // reset localStorage
  resetAchievedMissions();

  dispatch({
    type: RESET_QUIZ_RESULT,
  });

  callback();
};

export const saveQuizResult = (id, success) => (dispatch, getState) => {
  const { achievedBuildings } = getState();

  // save proggress to localStorage
  saveAchievedMissions([...achievedBuildings.set(id, success)]);

  dispatch({
    type: SAVE_QUIZ_RESULT,
    id,
    success,
  });
};

export const initAchievesMissions = achievedMissionsMap => ({
  type: INIT_ACHIEVED_MISSIONS,
  achievedMissionsMap,
});
