import React from 'react';

import { ReactComponent as cross } from 'assets/icons/cross.svg';
import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as trophy } from 'assets/icons/trophy.svg';
import { ReactComponent as like } from 'assets/icons/like.svg';
import { ReactComponent as prize } from 'assets/icons/prize.svg';
import { ReactComponent as podium } from 'assets/icons/podium.svg';

const icons = {
  cross,
  check,
  trophy,
  like,
  prize,
  podium,
};

const SVG = ({ name, width, height, ...props }) => {
  const Icon = icons[name];
  return <Icon width={!width && !height ? 22 : width} height={height} {...props} />;
};

export default SVG;
