import React from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Text } from 'react-native';

import Container from 'components/Container';
import Title from 'components/Title';
import NText from 'components/NText';
import SVG from 'components/SVG';
import NButton from 'components/NButton';
import { resetQuizz } from '../redux/actions';

const EndContainer = styled(Container)`
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
`;

const EndTitle = styled(Title)`
  margin-top: 10%;
`;

const ScoreContainer = styled.View`
  align-items: center;
`;

const SNContainer = styled.View`
  width: 100%;
`;

const SNText = styled(NText)`
  margin-bottom: 10%;
`;

const SNLinks = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;

const SNLink = styled.TouchableOpacity.attrs(({ name }) => ({
  children: <SVG name={name} height="30px" />,
  accessibilityRole: 'link',
  target: '_blank',
}))`
  flex: 1;
`;

const End = ({ quizSucceededCount, quizTotalCount, navigation, resetQuizz }) => {
  const { t, i18n } = useTranslation();
  return (
    <EndContainer>
      <SNContainer>
        <EndTitle>Bravo</EndTitle>

        <NText textAlign="center" style={{paddingTop: 30}}>{t('END_SCORE_TEXT')}</NText>

        <ScoreContainer>
          <NText textAlign="center" style={{paddingVertical:10}}>
            <NText fontWeight="bold">{quizSucceededCount}</NText>/{quizTotalCount}
          </NText>

          <SVG name="podium" width="40%" />
        </ScoreContainer>
      </SNContainer>
      <SNContainer>
        <NButton
          backgroundColor="primary"
          width="50%"
          style={{ marginHorizontal: 'auto', marginVertical: 5 }}
          onPress={() => navigation.navigate('Map')}>
          {t('END_BACK_BUTTON')}
        </NButton>
        <NButton
          backgroundColor="primary"
          width="50%"
          style={{ marginHorizontal: 'auto', marginVertical: 5 }}
          onPress={() => resetQuizz(() => navigation.navigate('Map', { pinpointId: null }))}>
          {t('END_RESTART_BUTTON')}
        </NButton>
      </SNContainer>
    </EndContainer>
  );
};

const mapStateToProps = state => {
  const buildings = state.buildings && state.buildings;
  const quizSucceededCount = buildings && buildings.reduce((a, e) => (e.success ? ++a : a), 0);

  return {
    quizTotalCount: buildings.length,
    quizSucceededCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetQuizz: (callback) => dispatch(resetQuizz(callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(End);
