import { createAppContainer } from 'react-navigation';
import { createStackNavigator } from 'react-navigation-stack';

import Map from './Map';
import Quiz from './Quiz';
import Score from './Score';
import End from './End';

const MainScreenStackNavigator = createStackNavigator(
  {
    Map,
    Quiz,
    Score,
    End,
  },
  {
    initialRouteName: 'Map',
    headerMode: 'none',
    navigationOptions: { header: { visible: false } },
    defaultNavigationOptions: {
      cardStyle: { backgroundColor: '#fff' },
    },
  },
);

const AppContainer = createAppContainer(MainScreenStackNavigator);

export default AppContainer;
