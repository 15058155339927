export const saveAchievedMissions = missionsMap => {
  const serializedState = JSON.stringify(missionsMap);
  localStorage.setItem('wn-game-progression', serializedState);
};

export const getAchievedMissionsMap = () => {
  const achievedMissions = localStorage.getItem('wn-game-progression');

  if (achievedMissions) {
    try {
      const achievedMissionsMap = new Map(JSON.parse(achievedMissions));
      return achievedMissionsMap;
    } catch (e) {
      throw new Error('No data in storage');
    }
  }

  return false;
};

export const resetAchievedMissions = () => {
  localStorage.removeItem('wn-game-progression');
};

