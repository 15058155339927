import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppNavigator from 'screens';

import { fetchDataWithRedux, initAchievesMissions } from './redux/actions';
import { getAchievedMissionsMap } from './services/storage';
import './utils/i18n';

// fuck this
const LIST_MAPPING = {
  19124: 79040,
  20663: 77416,
  23941: 84350,
  23942: 84350,
  24106: 84874,
  24109: 85030,
};

class App extends React.Component {
  componentDidMount = () => {
    const { fetchDataWithRedux, initAchievesMissions } = this.props;
    const queryParams = new URLSearchParams(window.location.search);
    const emmid = queryParams.get('emmid');

    const achievedMissionsMap = getAchievedMissionsMap();

    if (achievedMissionsMap) {
      initAchievesMissions(achievedMissionsMap);
    }

    fetchDataWithRedux({ listId: LIST_MAPPING[emmid] });
  };

  render() {
    return <AppNavigator />;
  }
}

const mapStateToProps = state => {
  return {
    data: state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDataWithRedux: ({ listId }) => dispatch(fetchDataWithRedux({ listId })),
    initAchievesMissions: missionsMap => dispatch(initAchievesMissions(missionsMap)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
