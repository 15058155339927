import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import { FONT_COLOR, FONT_SIZE, FONT_FAMILY } from 'constants/theme';
import { normalize } from 'constants/utils';

const NText = styled.Text`
  color: ${FONT_COLOR};
  font-size: ${props => normalize(FONT_SIZE(props))}px;
  font-family: ${FONT_FAMILY};
  text-transform: ${({ textTransform }) => textTransform};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

NText.propTypes = {
  fontSize: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
  fontColor: PropTypes.oneOf(['primary', 'secondary', 'third']),
};

NText.defaultProps = {
  fontSize: 'm',
  fontColor: 'primary',
  fontFamily: 'primary',
  textTransform: 'none',
  textAlign: 'left',
  fontWeight: 'normal',
};

export default NText;
