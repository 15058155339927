import React from 'react';
import styled from 'styled-components/native';

import { vh } from 'constants/units';
import Title from 'components/Title';
import CloseButton from 'components/CloseButton';

const Cover = styled.ImageBackground`
  height: ${25 * vh}px;
`;

const CoverTitle = styled(Title)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const QuizCover = ({ imageSource, title, navigation }) => {
  return (
    <Cover source={imageSource} resizeMode="cover">
      <CloseButton onPress={() => navigation.goBack()} color="#fff" />
      <CoverTitle backgroundColor="secondary">{title}</CoverTitle>
    </Cover>
  );
};

export default QuizCover;
