import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { loadScript } from 'constants/utils';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import { NavigationEvents } from 'react-navigation';

import SVG from 'components/SVG';

const ScoreButton = styled.TouchableOpacity`
  background-color: #cb0244;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
  margin-bottom: 5px;
`;

const wemapScript = loadScript('https://livemap.getwemap.com/js/sdk.min.js');

const QuizButtons = forwardRef(({ children }, ref) => {
  const [_isVisible, _setIsVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    setIsVisible: isVisible => _setIsVisible(isVisible),
  }));

  return _isVisible ? <div>{children}</div> : null;
});

class Map extends React.Component {
  emmidRef = React.createRef([]);

  openPinpoint = pinpointId => {
    window.livemap.openPinpoint(pinpointId);
  };

  addScoreButton = () => {
    const buttonsContainer = document.querySelector('div[class*="StackedContainer"]');
    const injectedButtons = document.createElement('div');

    injectedButtons.setAttribute('id', 'injected-buttons');
    buttonsContainer.insertBefore(injectedButtons, buttonsContainer.children[0]);

    ReactDOM.render(
      <QuizButtons
        ref={el => {
          this.quizButtonsRef = el;
        }}>
        <ScoreButton
          onPress={() => {
            this.props.navigation.navigate('Score');
          }}
          children={<SVG name="trophy" />}
        />
      </QuizButtons>,
      injectedButtons,
    );
  };

  showQuizButtonsOnQuizMaps = () => {
    window.wemap.v1.getPrivateInterface(i => {
      const { _livemap } = i.getFacade()._router._facade;
      const prototype = Object.getPrototypeOf(_livemap);

      this.quizButtonsRef.setIsVisible(this.isAQuizMap(_livemap.state.emmid));

      let newPrototype = Object.create(prototype);
      newPrototype.onSwitcherClick = action => {
        prototype.onSwitcherClick.call(_livemap, action);

        // OVERRIDE HERE
        this.quizButtonsRef.setIsVisible(this.isAQuizMap(action.emmid));
      };

      Object.setPrototypeOf(_livemap, newPrototype);
    });
  };

  onOpenPinpoint = ({ pinpoint }) => {
    const { achievedBuildings, buildings } = this.props;

    // Timeout to wait pinpoint description render
    setTimeout(() => {
      const elt = document.getElementsByClassName('wemap-template-button')[0];

      if (achievedBuildings.has(pinpoint.id)) {
        elt.textContent = this.props.t('MAP_ALREADY_PLAYED_TEXT');
        elt.style.setProperty('background-color', '#BABABA');
        elt.style.setProperty('box-shadow', '0 4px 0 #A19F9F');
        return;
      }

      if (buildings.map(b => b.id).includes(pinpoint.id)) {
        elt &&
          elt.addEventListener('click', e => {
            this.props.navigation.navigate('Quiz', {
              id: pinpoint.id,
            });
            window.livemap.closePinpoint();
            setTimeout(() => {
              window.livemap.setCenter({
                longitude: pinpoint.longitude,
                latitude: pinpoint.latitude,
              });
            }, 2000);
          });
      }
    }, 100);
  };

  // what fuck is it ?
  isAQuizMap = emmid => {
    return this.emmidRef.current.includes(emmid);
  };

  async componentDidMount() {
    const wemap = await wemapScript.then(() => window.wemap).catch(console.log);

    const queryParams = new URLSearchParams(window.location.search);
    const emmid = queryParams.get('emmid');

    if (emmid) {
      this.emmidRef.current = [Number(emmid)];
    } else {
      return;
    }

    const options = {
      emmid: emmid,
      initialbearing: 0,
      pitch: 0,
      method: 'dom',
      arviewenabled: true,
      deeplinkingenabled: false,
      enablepetals: false,
      arviewsearchradius: 50000,
    };
    window.livemap = wemap.v1.createLivemap(this.mapContainer, options, false);
    window.livemap.waitForReady().then(() => {
      window.livemap.addEventListener('pinpointOpen', this.onOpenPinpoint);

      this.addScoreButton();

      this.showQuizButtonsOnQuizMaps();
    });
  }

  render() {
    return (
      <>
        <NavigationEvents
          onDidFocus={({ state }) => {
            if (state && state.params && state.params.pinpointId) {
              this.openPinpoint(state.params.pinpointId);
              this.props.navigation.setParams({ pinpointId: null });
            }
          }}
        />
        <div
          style={{ height: '100%', position: 'relative' }}
          ref={node => {
            this.mapContainer = node;
          }}
        />
      </>
    );
  }
}

const mapStateToProps = ({ achievedBuildings, buildings }) => {
  return {
    achievedBuildings,
    buildings: buildings.map(({ id, name, media_url, success }) => ({
      id,
      name,
      imageSource: media_url,
      success,
    })),
  };
};

export default withTranslation()(connect(mapStateToProps)(Map));
