import React from 'react';
import styled from 'styled-components/native';

import SVG from 'components/SVG';

const CloseButton = styled.TouchableOpacity.attrs(({ color }) => ({
  children: <SVG name="cross" width={'100%'} color={color} />,
}))`
  width: 20px;
  margin: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export default CloseButton;
