import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';

import Container from 'components/Container';
import QuizButton from 'components/QuizButton';
import NButton from 'components/NButton';
import NText from 'components/NText';
import SVG from 'components/SVG';

const Quiz2Container = styled(Container)`
  align-items: center;
  justify-content: space-around;
`;

const ResultMessage = styled.View`
  align-items: center;
`;

const ButtonsWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const Quiz2 = ({ navigation, screenProps: { choices, response, navigation: appNavigation } }) => {
  const { t, i18n } = useTranslation();
  const success = navigation.getParam('success');
  return (
    <Quiz2Container>
      {success ? (
        <>
          <SVG name="prize" width={50} />
          <ResultMessage>
            <NText fontWeight="bold" textTransform="uppercase" style={{ marginBottom: '5%' }}>
            {t('QUIZ_RIGHT_ANSWER_TITLE')}
            </NText>
            <NText fontWeight="bold">{t('QUIZ_RIGHT_ANSWER_TEXT')}</NText>
          </ResultMessage>
        </>
      ) : (
        <>
          <SVG name="like" width={50} />
          <ResultMessage>
            <NText fontWeight="bold" textTransform="uppercase" style={{ marginBottom: '5%' }}>
            {t('QUIZ_FALSE_ANSWER_TITLE')}
            </NText>
            <NText fontWeight="bold">{t('QUIZ_FALSE_ANSWER_TEXT')}</NText>
          </ResultMessage>

          <QuizButton activeOpacity={1} kind="clicked">
            {choices[response.index]}
          </QuizButton>
        </>
      )}
      <NText textAlign="center" fontColor="secondary">
        {response.text}
      </NText>

      <ButtonsWrapper>
        <NButton
          width="45%"
          backgroundColor="secondary"
          iconName="trophy"
          onPress={() => {
            appNavigation.navigate('Score');
          }}>
          {t('QUIZ_SCORE_BUTTON')}
        </NButton>
        <NButton
          width="45%"
          backgroundColor="primary"
          onPress={() => {
            appNavigation.navigate('Map');
          }}>
          {t('QUIZ_NEXT_BUTTON')}
        </NButton>
      </ButtonsWrapper>
    </Quiz2Container>
  );
};

export default Quiz2;
