import theme from 'styled-theming';

const COLORS = {
  // nexity
  blue: '#002740',
  orange: '#ff8e70',
  yellow: '#FDD44F',
  black: '#000000',
  grey: '#808080',
  white: '#ffffff',

  // SNCF
  red: '#CB0244',
  black2: '#1D1D1D',
};

export const FONT_FAMILY = theme.variants('mode', 'fontFamily', {
  primary: { default: 'Lato' },
});

export const FONT_COLOR = theme.variants('mode', 'fontColor', {
  primary: { default: COLORS.black },
  secondary: { default: COLORS.grey },
  third: { default: COLORS.white },
});

export const FONT_SIZE = theme.variants('mode', 'fontSize', {
  xxs: { default: 10 },
  xs: { default: 12 },
  s: { default: 13 },
  m: { default: 16.5 },
  l: { default: 18 },
  xl: { default: 20 },
  xxl: { default: 22 },
});

export const BACKGROUND_COLOR_QUIZ_BUTTON = theme.variants('mode', 'kind', {
  clicked: { default: COLORS.blue },
  default: { default: COLORS.white },
});

export const COLOR_QUIZ_BUTTON = theme.variants('mode', 'kind', {
  clicked: { default: COLORS.white },
  default: { default: COLORS.black },
});

export const BACKGROUND_COLOR_BUTTON = theme.variants('mode', 'backgroundColor', {
  primary: { default: COLORS.red },
  secondary: { default: COLORS.black2 },
});

export const BACKGROUND_COLOR_TITLE = theme.variants('mode', 'backgroundColor', {
  primary: { default: COLORS.blue },
  secondary: { default: COLORS.red },
});
